@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$accentColor: #3A86FF;

@mixin bp-min($width) {
  @media screen and (min-width: $width) {
    @content
  }
}

@mixin bp-max($width) {
  @media screen and (max-width: $width) {
    @content
  }
}

body {
  background: $accentColor;
  font-size: 100%;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.liability {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: -1;

  @include bp-max(1024px) {
    left: 0;
    transform: unset;
    width: 100%;
  }

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: white;
    text-align: center;
  }
}

.page {
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @include bp-max(1024px) {
    width: calc(100% - 32px);
  }

  .header {
    height: 100px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .logotype {
      user-select: none;
      cursor: default;

      a {
        text-decoration: none;

        h1 {
          font-family: 'IBM Plex Mono', monospace;
          color: white;
          font-size: 24px;
          font-weight: 400;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .demo {
    width: 100%;
    //height: calc(100vh - 100px);
    position: relative;
    //display: flex;
    //flex-flow: column nowrap;
    //justify-content: center;
    //align-items: center;

    //flex: 0 0 100%;
    //flex-shrink: 0;
    //display: block;
    //position: absolute;
    //top: 50%;
    //left: 0;
    //transform: translate(0%, -50%);

    .container {
      width: 100%;
      //flex: 0 0 100%;
      //position: absolute;
      //top: 50%;
      //left: 0%;
      //transform: translate(0%, -50%);
    }

    .welcome {
      margin-top: 15vh;

      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      transition: .1s;
      margin-left: auto;
      margin-right: auto;

      @include bp-max(1024px) {
        width: calc(100% - 32px);
      }

      h2 {
        font-size: 36px;
        color: white;
        font-weight: 900;
        margin: 0;
        padding: 0;
        text-align: center;
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 25px;
      }

      @include bp-max(1024px) {
        h2 {
          font-size: 24px;
        }

        h3 {
          font-size: 18px;
        }
      }

      &-hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    .search {
      //margin-top: 15vh;
      margin-top: 15vh;
      width: calc(100% - 32px);
      //background: #000;
      //flex-shrink: 0;
      height: calc(100px - 32px);
      padding: 16px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transition: .1s;

      @include bp-max(1024px) {
        margin-top: 5vh;
        width: 100%;
        padding: 0;
        //width: 100%;
      }

      .input {
        width: calc(100% / 12 * 8); // 8 of 12 columns xD

        @include bp-max(1024px) {
          width: 100%;
        }

        input[type="text"] {
          width: calc(100% - 32px);
          height: 68px;
          outline: none;
          color: black;
          border-radius: 5px;
          border: none;
          padding: 0 16px;
          font-size: 18px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          //transition: .3s;

          &:focus {
            //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
            //box-sizing: border-box;
            //border: 10px solid black;
            //outline: 10px solid black;
            //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0 0 3pt 2pt rgba(#000, .5);
            //width: calc(100% - 32px - 6px);
            //border: 3px solid rgba(#3A86FF, .75);
          }
        }
      }

      &-focus {
        margin-top: calc(-15vh - 90px); //90px is height of welcome block
      }
    }

    .results {
      width: calc(100% - 32px);
      //height: 20px;
      //background: #fff;
      display: flex;

      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 16px;

      //margin-top: 100vh;
      margin-left: -100vw;
      position: absolute;
      //left: -9999px;
      left: -100vw;
      //display: none;
      //opacity: 0;
      //visibility: hidden;
      //transition: .1s;

      @include bp-max(1024px) {
        width: 100%;
        padding: 0;
        margin-top: 16px;
      }

      .wrapper {
        width: calc((100% / 12 * 8) - 32px);
        background: #fff;
        padding: 16px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
        //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);

        @include bp-max(1024px) {
          width: 100%;
        }

        .suggestions {
          h2 {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
            padding: 0;
          }

          .list {
            width: 100%;
            //margin-top: 16px;
            //

            .item {
              width: 100%;
              border-bottom: 1px solid rgba(#000, .1);
              //padding: 16px 0 18px 0;
              //padding-bottom: 16px;
              //margin-top: 20px;
              padding: 16px 0;

              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;

              &:last-child {
                border-bottom: none;
                padding-bottom: 0;
              }

              .image {
                width: 137px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                img {
                  width: 137px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 5px;
                }

                @include bp-max(1024px) {
                  width: 100px;

                  img {
                    width: 100px;
                  }
                }
              }

              .info {
                margin-left: 16px;
                //width: calc(60% - 16px);
                width: 70%;

                display: flex;
                flex-flow: column nowrap;
                //align-items: center;
                justify-content: center;

                .title {
                  font-size: 18px;
                  font-weight: 700;
                  //margin-top: -10px;
                }

                .description {
                  font-size: 14px;
                }

                @include bp-max(1024px) {
                  //width: calc(70% - 16px - 100px);
                  margin-left: 16px;
                  margin-right: 16px;

                  .title {
                    font-size: 14px;
                  }

                  .description {
                    font-size: 12px;
                  }
                }
              }

              .add {
                //width: 20%;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                button {
                  width: 100%;

                  border: 0;
                  outline: none;
                  //border-radius: 0;
                  margin: 0;
                  //padding: 0;

                  background: #ED7744;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
                  border-radius: 5px;
                  color: white;
                  font-size: 18px;
                  font-weight: 700;

                  padding: 23px 25px;

                  cursor: pointer;

                  &:hover {
                    background: darken(#ED7744, 10%);
                  }

                  @include bp-max(1024px) {
                    padding: 8px 10px;
                  }
                }
              }
            }
          }
        }

      }

      &-focus {
        //margin-top: 0;
        //display: flex;
        //opacity: 1;
        margin-left: 0;
        position: relative;
        left: 0;
        //position: absolute;
        //left: -9999px;
      }
    }

    .productlist {
      width: calc(100% - 32px);
      //height: 20px;
      //background: #fff;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 16px;

      //margin-top: 100vh;
      //margin-top: 100vw;
      position: absolute;
      top: 9999px;
      //opacity: 0;
      //transition: .1s;

      //max-height: 50vh;
      //height: 55vh;

      @include bp-max(1024px) {
        margin-top: 16px;
        padding: 0;
        width: 100%;
        flex-flow: column wrap;
        //height: 30vh;
      }

      .wrapper {
        width: calc((100% / 12 * 8) - 32px);
        background: #fff;
        padding: 16px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
        //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);

        @include bp-max(1024px) {
          width: calc(100% - 32px);
          height: 60vh;
          //overflow: hidden;
          overflow-y: scroll;
        }

        .counter {
          //padding: 16px;
          //width: calc(100% - 32px);
          display: flex;
          flex-flow: row nowrap;

          @include bp-max(1024px) {
            flex-flow: column wrap;
          }

          .list {
            width: 60%;
            height: 60vh;
            overflow: scroll;

            @include bp-max(1024px) {
              width: 100%;
              height: auto;
            }

            h2 {
              margin: 0;
              padding: 0;
              font-size: 24px;
              font-weight: 700;
            }

            .items {
              width: 100%;
              //overflow: scroll;

              .item {
                border-bottom: 1px solid rgba(#000, .1);
                padding: 16px 0;
                //width: 100%;

                //display: flex;
                //flex-flow: row nowrap;
                //justify-content: space-between;
                //align-items: center;

                &:last-child {
                  border-bottom: 0;
                  padding-bottom: 0;
                }

                .default {
                  display: flex;
                  flex-flow: row nowrap;
                  justify-content: space-between;
                  align-items: center;

                  .image {
                    width: 137px;
                    height: 100px;

                    img {
                      width: 137px;
                      height: 100px;
                      object-fit: cover;
                      border-radius: 5px;
                    }

                    @include bp-max(1024px) {
                      width: 100px;

                      img {
                        width: 100px;
                      }
                    }
                  }

                  .title {
                    font-size: 18px;
                    font-weight: 700;
                    //margin-left: 16px;
                  }

                  //.count {
                  //  //white-space: pre-line;
                  //  text-align: center;
                  //}

                  .actions {
                    button {
                      margin: 0;
                      //padding: 0;
                      border: 0;
                      appearance: none;
                      background: rgba(#000, .05);
                      width: 32px;
                      height: 32px;
                      border-radius: 90px;

                      display: flex;
                      flex-flow: row nowrap;
                      //justify-content: center;
                      align-items: center;
                      justify-content: space-between;
                      padding: 0 8px;
                      cursor: pointer;

                      &:hover {
                        background: rgba(#000, .1);
                      }

                      &:active {
                        background: rgba($accentColor, .15);
                      }

                      .dot {
                        width: 4px;
                        height: 4px;
                        border-radius: 90px;
                        background: rgba(#000, .5);

                        //margin-right: 3px;

                        &:last-child {
                          //margin-right: 0;
                        }
                      }
                    }

                  }
                }

                .options {
                  display: none;
                  visibility: hidden;
                  flex-flow: row wrap;
                  padding: 16px 0 0 0;
                  justify-content: space-between;

                  @include bp-max(414px) {
                    flex-flow: column wrap;
                  }

                  .count {
                    display: flex;
                    flex-flow: row;
                    align-items: center;

                    @include bp-max(414px) {
                      margin-bottom: 16px;
                    }

                    .number {
                      margin: 0 8px;
                      min-width: 32px;
                      text-align: center;
                    }

                    &-update {
                      button {
                        margin: 0;
                        //padding: 0;
                        border: 0;
                        appearance: none;
                        background: rgba(#000, .05);
                        width: 32px;
                        height: 32px;
                        border-radius: 90px;
                        //font-weight: 900;
                        font-size: 18px;

                        cursor: pointer;

                        &:hover {
                          background: rgba(#000, .1);
                        }

                        &:active {
                          background: rgba($accentColor, .15);
                        }
                      }
                    }
                  }

                  .weight {
                    display: flex;
                    flex-flow: row;
                    align-items: center;

                    @include bp-max(414px) {
                      margin-bottom: 16px;
                    }

                    .label {
                      margin-right: 8px;
                    }

                    input[type="number"] {
                      //width: auto;
                      border: 0;
                      margin: 0;
                      //padding: 0;
                      appearance: none;
                      outline: none;
                      width: 64px;
                      background: rgba(#000, .05);
                      padding: 8px;
                      border-radius: 5px;
                      font-size: 14px;

                      &:hover {
                        background: rgba(#000, .1);
                      }

                      &:focus {
                        //background: rgba(#000, .15);
                        background: rgba($accentColor, .15);
                      }
                    }
                  }

                  .rem {
                    button {
                      border: 0;
                      margin: 0;
                      appearance: none;
                      outline: none;
                      background: rgba(#000, .05);
                      padding: 8px;
                      border-radius: 5px;
                      font-size: 14px;
                      cursor: pointer;

                      &:hover {
                        background: rgba(#000, .1);
                      }

                      &:active {
                        background: rgba($accentColor, .15);
                      }
                    }
                  }

                  &-show {
                    display: flex;
                    visibility: visible;
                  }
                }
              }
            }
          }

          .echo {
            width: calc(40% - 16px);
            margin-left: 16px;

            @include bp-max(1024px) {
              width: 100%;
              margin-left: 0;
              margin-top: 16px;
            }

            .full {
              background: rgba(#000, .05);
              display: flex;
              flex-flow: column nowrap;
              text-align: center;
              padding: 32px;
              margin-top: 16px;
              border-radius: 5px;

              @include bp-max(1024px) {
                padding: 16px;
              }

              &:first-child {
                margin-top: 0;
              }

              .total {
                font-size: 32px;
                font-weight: 700;

                @include bp-max(1024px) {
                  font-size: 24px;
                }
              }

              .bzu {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                padding: 16px;

                .num {
                  width: calc(100% / 3);
                  font-size: 18px;

                  span {
                    font-weight: 700;
                  }
                }
              }

              .info {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }

      &-focus {
        //margin-top: 0;
        //margin-top: 0;
        position: relative;
        top: 0;
        //transition: .3s;
        //opacity: 1;
      }
    }
  }
}